.Speaker {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    height: 100%;
    cursor: pointer;
}

.SpeakerText {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    > h2 {
        color: var(--colour-background-accent-secondary);
        margin: 0;
        padding-bottom: 0;
        padding-top: var(--spacing-small-half);
    }
    > h3 {
        margin: 0;
    }
    > p {
        margin: 0;
        padding: 0;
    }
}

.Image {
    position: relative;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: var(--spacing-small);
    > img {
        border-radius: var(--spacing-small);
    }
}
