.SpeakerList {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-medium);

    > ul {

        width: 100%;
        padding: 0;
        display: grid;
        list-style-type: none;
        align-items: center;
        justify-content: center;

        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        gap: var(--spacing-small);

        @media ( max-width: 600px ) {
            grid-template-columns: 50% 50%;
            gap: var(--spacing-small-half);
        }

        > li {
            list-style-type: none;
            min-height: 0;
            height: 100%;
        }
    }

}
